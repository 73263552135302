import { useLayoutEffect } from 'react'

import { enableDarkMode, disableDarkMode } from '@interco/inter-ui'
import { interWbSession } from '@interco/inter-webview-bridge'

const DarkModeLoader = () => {
  const handleDarkMode = async () => {
    const appInfo = await interWbSession.getAppInfo()
    if (appInfo.isDarkMode) {
      document.body.style.backgroundColor = '#1C1C1E'
      enableDarkMode()
      localStorage.setItem('isDarkMode', 'true')
    } else {
      document.body.style.backgroundColor = '#FFFFFF'
      disableDarkMode()
      localStorage.setItem('isDarkMode', 'false')
    }
  }

  useLayoutEffect(() => {
    handleDarkMode()
  }, [])

  return <div />
}

export default DarkModeLoader
