import { useEffect, useState } from 'react'

import { IconTheme } from 'components/IconTheme'
import { Separator } from 'components/Separator'
import { ItemsApiProps } from 'services/account-info/types'

import { Card as CardUI } from '@interco/inter-ui/components/Card'
import { Flex, FlexColumn, FlexRow } from '@interco/inter-ui/components/Flex'
import { Text } from '@interco/inter-ui/components/Text'
import { interWbSession } from '@interco/inter-webview-bridge'

interface CardProps {
  title?: string
  items?: ItemsApiProps[]
}

export const Card = ({ title, items }: CardProps) => {
  const [copied, setCopied] = useState<string>('')

  useEffect(() => {
    setTimeout(() => {
      copied && setCopied('')
    }, 2000)
  }, [copied])

  const handleShareClick = () => {
    const message = items?.map(
      (item) => `${item.titulo}: ${item.dados[0] ?? ''} ${item.dados[1] ?? ''}\n`,
    )

    interWbSession.shareContent(`${title}:\n\n${message?.join('')}`)

    interWbSession.requestAnalytics('TRANSFERIR_GA_FLUXO', {
      ref_figma: '65',
      flow: 'dados-bancarios',
      screen: 'dados-bancarios',
      ref_type: 'button',
      content_action: 'touch',
      action_id: 'share',
      share: title ?? '',
    })
  }

  const handleCopyClick = (copyTitle: string, data: string) => {
    interWbSession.copyToClipboard(data, data)
    setCopied(copyTitle)

    interWbSession.requestAnalytics('TRANSFERIR_GA_FLUXO', {
      ref_figma: '66',
      flow: 'favoritos',
      screen: 'dados-bancarios',
      ref_type: 'input',
      content_action: 'touch',
      action_id: 'copy',
      copy: copyTitle,
    })
  }

  return (
    <CardUI className="border border-solid border-[var(--gray200)]">
      <Flex className="gap-4">
        <FlexRow alignItems="center" gap="0.5rem">
          <Text variant="body-3" as="p" bold colorWeight={500}>
            {title}
          </Text>
          <IconTheme
            icon="orangeds/MD/share"
            className="shrink-0"
            width={24}
            height={24}
            onClick={handleShareClick}
          />
        </FlexRow>
        <Separator variant="dashed" />
        <FlexColumn gap="8px">
          {items?.map((item) =>
            item.dados[1] ? (
              <FlexColumn key={item.titulo} gap="8px">
                <Text variant="caption-1" as="p">
                  {item.titulo}
                </Text>
                <Text variant="caption-1" as="p" colorWeight={500}>
                  {item.dados[0]} <br />
                  {item.dados[1]}
                </Text>
              </FlexColumn>
            ) : (
              <FlexColumn key={item.titulo} gap="8px">
                <Text variant="caption-1" as="p">
                  {item.titulo}
                </Text>
                <FlexRow alignItems="center" justifyContent="flex-start" gap="10px">
                  <Text variant="caption-1" as="p" bold colorWeight={500}>
                    {item.dados[0]}
                  </Text>
                  {copied === item.titulo ? (
                    <IconTheme icon="orangeds/MD/check-fill" width={20} height={20} />
                  ) : (
                    <IconTheme
                      icon="orangeds/MD/copy"
                      width={20}
                      height={20}
                      color="var(--gray400)"
                      onClick={() => handleCopyClick(item.titulo, item.dados[0])}
                    />
                  )}
                </FlexRow>
              </FlexColumn>
            ),
          )}
        </FlexColumn>
      </Flex>
    </CardUI>
  )
}
