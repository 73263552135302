import { useAppInfo } from 'hooks/useAppInfo'
import { data } from 'pages/InstantPayments/data'
import { Separator } from 'components/Separator'
import { ThemesEnum } from 'common/enums/themes'

import FlashOn from '@interco/icons/orangeds/MD/flash-on'
import Pending from '@interco/icons/orangeds/MD/pending'
import ISafe from '@interco/icons/orangeds/MD/i-safe'
import { FlexColumn, FlexRow } from '@interco/inter-ui/components/Flex'
import { Text } from '@interco/inter-ui/components/Text'
import { Button } from '@interco/inter-ui/components/Button'
import { interWbNavigate } from '@interco/inter-webview-bridge'
import { Tag } from '@interco/inter-ui/components/Tag'

export const InstantPayments = () => {
  const appInfo = useAppInfo()
  const selectedLanguage = appInfo?.language ?? 'pt-BR'

  const handleBackButton = () => interWbNavigate.requestGoBack()

  return (
    <FlexColumn className="px-6" height="100vh">
      <FlexColumn gap="32px">
        <FlexColumn key="conta.nome" className="mt-4" gap="8px">
          <Tag
            size="small"
            intensity="light"
            theme={appInfo?.application === ThemesEnum.PJ ? 'turquoise' : 'orange'}
          >
            {data[selectedLanguage].tag}
          </Tag>
          <Text variant="headline-h2" as="h2" semiBold>
            {data[selectedLanguage].title}
          </Text>
        </FlexColumn>
        <FlexColumn gap="16px">
          <FlexRow gap="16px" justifyContent="flex-start" alignItems="center">
            <FlashOn height={24} width={24} color="var(--gray500)" />
            <Text variant="body-3" as="p" colorWeight={500}>
              {data[selectedLanguage].stepOne}
            </Text>
          </FlexRow>
          <Separator variant="line" />
          <FlexRow gap="16px" justifyContent="flex-start" alignItems="center">
            <Pending height={24} width={24} color="var(--gray500)" />
            <Text variant="body-3" as="p" colorWeight={500}>
              {data[selectedLanguage].stepTwo}
            </Text>
          </FlexRow>
          <Separator variant="line" />
          <FlexRow gap="16px" justifyContent="flex-start" alignItems="center">
            <ISafe height={24} width={24} color="var(--gray500)" />
            <Text variant="body-3" as="p" colorWeight={500}>
              {data[selectedLanguage].stepThree}
            </Text>
          </FlexRow>
          <Separator variant="line" />
        </FlexColumn>
      </FlexColumn>
      <FlexColumn className="pb-6">
        <Button fullWidth onClick={handleBackButton}>
          {data[selectedLanguage].primaryButton}
        </Button>
      </FlexColumn>
    </FlexColumn>
  )
}
